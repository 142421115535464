<template>
  <section>
    <van-nav-bar title="充值合同" left-arrow @click-left="backSpace" />
    <van-form @submit="submit">
      <van-field
        v-model="form.name"
        name="项目名称"
        label="项目名称"
        placeholder="项目名称"
      />
      <van-field
        v-model="form.company"
        name="甲方单位"
        label="甲方单位"
        placeholder="甲方单位"
      />
      <van-field
        v-model="form.price"
        name="金额"
        label="金额"
        placeholder="金额"
      />
      <van-field
        v-model="form.code"
        name="发票抬头"
        label="发票抬头"
        placeholder="发票抬头"
      />
      <van-field
        v-model="form.num"
        name="税号"
        label="税号"
        placeholder="税号"
      />
      <div class="module">
        <div class="module_title fa sb">
          <span>项目内容</span>
        </div>
        <textarea
          v-model="form.content"
          type="text"
          placeholder="请输入项目内容"
        />
      </div>
      <van-field
        v-model="form.receiver_name"
        name="收货人"
        label="收货人"
        placeholder="收货人"
      />
      <van-field
        v-model="form.receiver_phone"
        name="电话"
        label="电话"
        placeholder="电话"
      />
      <van-field
        v-model="form.receiver_address"
        name="收货地址"
        label="收货地址"
        placeholder="收货地址"
      />
      <div @click="preview" class="preview">
        下载合同模板
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </section>
</template>
<script>
import Vue from "vue";
import qs from "qs";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
import {
  contractCreate,
  getMemberInfo,
  hetong,
} from "@/assets/api/api";
import { Uploader } from "vant";
Vue.use(Uploader);
export default {
  data() {
    return {
      form: {
        name: "",
        company: "",
        price: "",
        content: "",
        code: "",
        num: "",
        receiver_name: "",
        receiver_phone: "",
        receiver_address: "",
      },
    };
  },
  computed: {},
  methods: {
    submit() {
      if (this.form.name == "") {
        Toast("项目名称不能为空");
      } else if (this.form.company == "") {
        Toast("甲方单位不能为空");
      } else if (this.form.price == "") {
        Toast("金额不能为空");
      } else if (this.form.content == "") {
        Toast("项目内容不能为空");
      } else if (this.form.receiver_name == "") {
        Toast("收货人不能为空");
      } else if (this.form.receiver_phone == "") {
        Toast("电话不能为空");
      } else if (this.form.receiver_address == "") {
        Toast("收货地址不能为空");
      } else {
        this.contractCreate();
      }
    },
    preview() {
      window.location.href="http://shop.wesky.online/./word/Template.docx";
      // this.$router.push("preview?src=" + "./word/Template.docx");
    },
    // preview() {
    //   if (this.form.name == "") {
    //     Toast("项目名称不能为空");
    //   } else if (this.form.company == "") {
    //     Toast("甲方单位不能为空");
    //   } else if (this.form.price == "") {
    //     Toast("金额不能为空");
    //   } else if (this.form.content == "") {
    //     Toast("项目内容不能为空");
    //   } else if (this.form.receiver_name == "") {
    //     Toast("收货人不能为空");
    //   } else if (this.form.receiver_phone == "") {
    //     Toast("电话不能为空");
    //   } else if (this.form.receiver_address == "") {
    //     Toast("收货地址不能为空");
    //   } else {
    //     Toast.loading({
    //       message: "加载中...",
    //       forbidClick: true,
    //       loadingType: "spinner",
    //       duration: 0,
    //     });
    //     preview(qs.stringify(this.form)).then((res) => {
    //       Toast.clear();
    //       if (res.data.code == 0) {
    //         Toast("加载成功");
    //         this.$router.push("preview?src=" + res.data.data);
    //       } else {
    //         Toast(res.data.message);
    //       }
    //     });
    //   }
    // },
    contractCreate() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      contractCreate(qs.stringify(this.form)).then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          Toast("提交成功");
          setTimeout(() => {
            this.$router.replace("orderList");
            // this.topay(res.data.data.id, res.data.data.price);
          }, 1000);
        } else {
          Toast(res.data.message);
        }
      });
    },
    topay(id, pay) {
      this.$router.push("payoff?id=" + id + "&price=" + pay);
    },
    getMemberInfo() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      getMemberInfo().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          this.form.code = res.data.data.code;
          this.form.num = res.data.data.num;
          this.form.receiver_name = res.data.data.realname;
          this.form.receiver_phone = res.data.data.phone;
          // this.hetong()
        } else {
          Toast(res.data.message);
        }
      });
    },
    hetong() {
      hetong().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          console.log(res.data);
        } else {
          Toast(res.data.message);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getMemberInfo();
  },
};
</script>
<style scoped>
.module {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
.module textarea {
  border: 1px solid #ebedf0;
  padding: 5px;
  min-width: 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 10px;
  height: 100px;
}
.preview {
  font-size: 14px;
  color: #1989fa;
  padding: 10px 20px;
  text-align: right;
}
.preview_link {
  border-bottom: 1px solid #333;
}
</style>
